import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { Stack } from '../../components/Core';
import Hero from '../../components/Hero';
import { HERO_TYPE } from '../../components/Hero/constants';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import FlexibleContent from '../../components/Serializers/FlexibleContent';
import { createImageWithArtDirection } from '../../utils/serializeImageForImageComponent';

type IFlexiblePageTemplate = PageProps<Queries.PageBuilderQuery, IPageContext>;

const FlexiblePageTemplate: FCWithClassName<IFlexiblePageTemplate> = (
  props
) => {
  const { data, pageContext } = props;
  const pageData = data.wpPage;
  const pageTemplateData = 'contenuPageBuilder' in data.wpPage.template ? data.wpPage.template.contenuPageBuilder : null;
  if (!pageData || !pageContext.defaultLanguage || !pageTemplateData) return null;
  const flexibleBlocks = pageTemplateData.blocs;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore we have to override the type of fieldGroupName
  const serializedBlocks = <FlexibleContent blocks={flexibleBlocks} />;
  const heroImageWithArtDirection = createImageWithArtDirection({
    desktopImage: pageData.featuredImage?.node,
    mobileImage: pageData.hero.imageMobile,
  });
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <Stack className="space-y-gap">
        <Hero
          backgroundImage={heroImageWithArtDirection}
          couleurTitre={pageData.hero.couleurTitre}
          heading={pageData.hero.titre}
          tagline={pageData.hero.tagline}
          type={HERO_TYPE.STATIC}
        />
        {serializedBlocks}
      </Stack>
    </Layout>
  );
};

export default FlexiblePageTemplate;

export const query = graphql`
  query PageBuilder($id: String) {
    wpPage(id: { eq: $id }) {
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      language {
        locale
        slug
      }
      translations {
        language {
          code
          locale
        }
        uri
      }
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      hero {
        couleurTitre
        tagline
        titre
        imageMobile {
          ...MediaItemFragment
          localFile {
            ...MobileHeroImage
          }
        }
      }
      template {
        ...on WpTemplate_PageBuilder {
          contenuPageBuilder {
            blocs {
              ... on WpTemplate_PageBuilder_Contenupagebuilder_Blocs_BlocFeature {
                fieldGroupName
                couleur
                description
                titre
                alignement
                image {
                  ...MediaItemFragment
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                    publicURL
                  }
                }
                blockType
              }
              ... on WpTemplate_PageBuilder_Contenupagebuilder_Blocs_BlocPresentation {
                fieldGroupName
                couleur
                description
                titre
                photo {
                  ...MediaItemFragment
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                    publicURL
                  }
                }
              }
              ... on WpTemplate_PageBuilder_Contenupagebuilder_Blocs_BlocDeTexte {
                fieldGroupName
                texte
              }
              ... on WpTemplate_PageBuilder_Contenupagebuilder_Blocs_Ctas {
                orientation
                fieldGroupName
                buttons {
                  fieldGroupName
                  style
                  titre
                  link {
                    target
                    title
                    url
                  }
                }
              }
              ... on WpTemplate_PageBuilder_Contenupagebuilder_Blocs_TextBlocks {
                fieldGroupName
                blocs {
                  fieldGroupName
                  texte
                  titre
                  largeurMaxDuBloc
                }
              }
              ... on WpTemplate_PageBuilder_Contenupagebuilder_Blocs_Section {
                description
                fieldGroupName
                titre
                largeur
                couleur
              }
            }
          }
        }
      }
    }
  }
`;
